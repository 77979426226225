@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #979797 #d6d6d6;
  }

  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #d6d6d6;
    border-radius: 2.5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 100vh;
    background-color: #979797;
    height: 40px;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #afafaf;
  }
}

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

@keyframes onAutoFillStart {
}

div[role="textbox"] {
  outline: none;
}
