@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

html,
body,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  background-color: theme("colors.background");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.TabActive {
  color: #3e5f96;
  border-bottom: 4px solid #3e5f96;
  margin-bottom: 0;
  opacity: 1;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.empty[type="date"]::-webkit-datetime-edit {
  display: none;
}

input[type="date"][readonly] {
  -webkit-text-fill-color: theme("colors.even-row-text");
}

input[disabled],
textarea[disabled] {
  -webkit-text-fill-color: theme("colors.disabled");
}

input:disabled,
textarea:disabled {
  background-color: unset;
  color: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.last-shiny:last-child {
  overflow: hidden !important;
}

.last-shiny:last-child::after {
  top: 0;
  left: 0;
  content: "";
  width: 1000px;
  height: 50px;
  margin-left: -100px;
  transform: rotate(30deg);
  background: linear-gradient(
    to left top,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  position: absolute;
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  100% {
    margin-top: -100px;
    opacity: 1;
  }
  40% {
    margin-top: 0;
    opacity: 1;
  }
  0% {
    margin-top: 400px;
    opacity: 0;
  }
}

.card-shuffle {
  animation: shuffle 0.75s ease-in-out 1 forwards;
}

.card-shuffle-backwards {
  animation: shuffle-backwards 0.5s ease-in-out 1 forwards;
}

@keyframes shuffle {
  0% {
    margin-left: -100%;
    z-index: 20;
    rotate: 0deg;
  }
  50% {
    margin-left: 30%;
    z-index: 20;
    rotate: 5deg;
  }
  51% {
    margin-left: 30%;
    z-index: 0;
    rotate: 5deg;
  }
  100% {
    margin-left: -100%;
    z-index: 0;
    rotate: 0deg;
  }
}

@keyframes shuffle-backwards {
  100% {
    margin-left: -100%;
    z-index: 20;
    rotate: 0deg;
  }
  51% {
    margin-left: 30%;
    z-index: 20;
    rotate: 5deg;
  }
  50% {
    margin-left: 30%;
    z-index: 0;
    rotate: 5deg;
  }
  0% {
    margin-left: -100%;
    z-index: 0;
    rotate: 0deg;
  }
}

.skeleton-box {
  position: relative;
  overflow: hidden;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 5s infinite;
  animation: shimmer 5s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
