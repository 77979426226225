.base-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.24em;
  height: 1.24em;
  border-radius: 0.25em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  border: 0.2em solid theme('colors.main');
  background-color: white;
  outline: none;
  cursor: pointer;
}

.base-checkbox input:checked {
  background-color: theme('colors.main');
  position: relative;
}

.base-checkbox input:disabled {
  cursor: default;
}

.base-checkbox input:checked::before {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  top: 0.15em; 
  margin: -0.05em;
  width: 1em;
  height: 0.5em;
  border-radius: 1px;
  border-top: 0px solid theme('colors.main');
  border-right: 0px solid theme('colors.main');
  border-left: 4px solid white;
  border-bottom: 4px solid white;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.base-checkbox input:indeterminate:not(:disabled)::before{
  content: "";
  position: relative;
  width: 0.62em;
  height: 0.62em;
  border-radius: 2px;
  border: solid;
  margin-left: 0.12em;
  margin-top: 0.12em;
  display: block;
  border-color: theme('colors.main');;
  background-color: theme('colors.main');;
}

.base-checkbox input:indeterminate:disabled::before{
  content: "";
  position: relative;
  width: 0.62em;
  height: 0.62em;
  border-radius: 2px;
  border: solid;
  margin-left: 0.12em;
  margin-top: 0.12em;
  display: block;
  border-color: theme('colors.disabled');
  background-color: theme('colors.disabled');
}

.base-checkbox input[type="checkbox"]:disabled:checked {
  border-color: theme('colors.disabled');;
  background-color: theme('colors.disabled');;
}

.base-checkbox input[type="checkbox"]:disabled:not(:checked) {
  border-color: theme('colors.disabled');;
}

.base-checkbox input[type="checkbox"]:disabled + span {
  color: white;
}

.base-checkbox input[type="checkbox"]:is(:hover, :focus):not(:disabled) {
  box-shadow: 0 0 0.2rem theme('colors.main');
}